<template>
    <div class="container-fluid">
		<div class="row">
        	<h4>{{ horsesName }}</h4>
			<form v-if="!loading && form" @submit.prevent="() => false" class="col-12">
				<div class="form-group">
					<label>{{ $t('notes.title') }} *</label>

					<b-form-input
						:placeholder="$t('notes.title_placeholder')"
						v-model="form.title"
					/>
				</div>

				<div class="form-group" >
					<label>{{ $t('global.date') }} *</label>
					<e-datepicker v-model="form.date" mode="date"></e-datepicker>
				</div>

				<div class="form-group">
					<label>{{ $t('notes.commentaire') }}</label>

					<e-editor-froala
						identifier="note_comment"
						:placeholder="$t('notes.commentaire')"
						:content="form.comment"
						@change="updateEditorComment"
					></e-editor-froala>
				</div>

				<div class="form-group">
					<label>{{ $t('notes.attachment') }}</label>

					<div v-for="(file, idx) in form.files" :key="file.files_id" class="row mb-2">
						<e-select
							class="col-2"
							v-model="form.files[idx].type"
							:options="file_types"
						>
                            <template slot="singleLabel" slot-scope="{ option }">{{ $t(`fichier.type.${option}`) }}</template>
							<template slot="option" slot-scope="{ option }">{{ $t(`fichier.type.${option}`) }}</template>
						</e-select>

						<b-form-file
							v-show="file.type == 'file'"
							class="col-6"
							v-model="form.files[idx].content"
							:state="hasLinkedFile && isValidSizeFileMultiple"
							:placeholder="file.files_original_name || fileInputPlaceholder"
							:drop-placeholder="file.files_original_name || $t('fichier.drop_file')+'...'"
							@change="updatedLinkedFile(idx)"
						></b-form-file>

						<b-form-input
							v-show="file.type == 'embed'"
							class="col-6"
							:placeholder="file.files_url || embedInputPlaceHolder"
							:allow-empty="false"
							v-model="form.files[idx].files_url"
							@change="updatedLinkedFile(idx)"
						/>

						<span class="col-2">
							<b-button @click="addFile">
								<font-awesome-icon :icon="['fal', 'plus-circle']" />
							</b-button>
						</span>
					</div>
				</div>
								
				<div class="form-group" v-if="groups.length > 0">
					<label>{{ $t("note.autorisation_groups") }}</label>

					<e-select
						v-model="form.groups"
						id="group_id"
						:placeholder="$t('note.placeholder.autorisation_groups')"
						track-by="group_id"
						label="group_label"
						:options="groups"
						:searchable="true"
						:allow-empty="true"
						:loading="loading_groups"
						:multiple="true"
					/>
				</div>

				<div class="form-group" v-if="categories.length > 0">
					<label>{{ $t("menu.setting.categories") }}</label>

					<e-select
						v-model="form.categories"
						id="categorienote_id"
						track-by="categorienote_id"
						label="categorienote_libelle"
						:options="categories"
						:searchable="true"
						:allow-empty="true"
						:multiple="true"
					/>
				</div>

				<div class="row mt-2" v-if="hasDedicatedClientArea">
					<div class="col-12">
						<b-form-checkbox id="note_display_ca" v-model="form.display_ca"><label for="note_display_ca">{{ $t("table.tiers_horse_horse.horse.set_ca_displayable") }}</label></b-form-checkbox>
					</div>
				</div>

				<div class="text-center">
					<button @click="checkForm" class="btn btn-primary rounded-pill" :disabled="working">
						<font-awesome-icon :icon="buttonFont" :pulse="working" /> {{ buttonText }}
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common.js'
	import _defaults from 'lodash/defaults'
	import _cloneDeep from 'lodash/cloneDeep'
    import FileMixin from '@/mixins/File.js'
    import Notes from '@/mixins/Notes.js'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterNotes from '@/mixins/shutters-manager/Notes.js'
    import HorseMixin from "@/mixins/Horse.js";

	export default {
        name: "TriggersWriteNote",
        mixins: [FileMixin, HorseMixin, Notes, Shutter, ShutterNotes],
		props: {
			horses_ids: { type: Array, default: () => [] },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
		},
		data () {
			return {
                default_form: {
					title: '',
					date: new Date(),
					comment: '',
					files: [],
					filename: '',
					act: null,
					display_ca: true,
					groups: [],
					categories: []
				},
				form: null,
				acts: [],
				file_types: ['file', 'embed'],
				loading: true,
				working: false,
				deleted_files: [],
				horses_infos: [],
                document_limit_size: '10', // en Mo
				groups: [],
				categories: []
			}
        },
        created() {
			this.preinit_component()
        },
        methods: {
			async preinit_component() {
				this.loading = true

				this.form = _cloneDeep(this.default_form)

				this.getHorsesWhishlist(this.horses_ids)
                        .then(res => this.horses_infos = res)

				this.categories = await this.getAllCategorieNote()

				this.addFile()
				this.loading = false
			},

			updateEditorComment(hook) {
				this.form.comment = hook.val
			},

			updatedLinkedFile(idx) {
				this.form.files[idx].updated = true
				this.deleted_files.push(this.form.files[idx])
			},

			checkForm() {
				if(this.form.title.length === 0) return

				return this.saveForm()
			},

			addFile() {
				this.form.files.push({
					files_id: Common.getNegativeId(),
					files_original_name: '',
					files_url: '',
					content: new File([], 'blank'),
					type: this.file_types[0],
					updated: false
				})
			},

			rmFile(idx) {
				const file = this.form.files[idx]

				// Si c'est un fichier déjà enregistré
				if(file.files_id > 0) {
					this.deleted_files.push(file)
				}

				this.form.files.splice(idx, 1)
			},

			async saveForm() {
				try {
					this.working = true

					let tab_promises = []

					await Common.asyncForEach(this.horses_ids, async (horse_id) => {
						const categories_ids = this.form.categories.map(c => c.categorienote_id)
						const inserted = await this.saveNote(horse_id, 0, 0, this.form.title, this.form.date.toFormattedString(), this.form.comment, this.form.display_ca, this.form.groups, categories_ids)
						const files = this.form.files.filter(f => f.content.size > 0 && f.content.name != 'blank' && f.type == 'file')
						const embed = this.form.files.filter(f => f.files_url != '' && f.type == 'embed')

						if(this.hasLinkedFile) {
							files.forEach(file => {
								tab_promises.push(this.uploadMediaFile(file.content, inserted.note_id, "App\\Model\\Note"))
							})
						}

						embed.forEach(file => {
							const file_url = this.formatEmbedUrl(file.files_url)
							tab_promises.push(this.uploadEmbeddMedia(file_url, inserted.note_id, "App\\Model\\Note"))
						})
					})

					await Promise.all(tab_promises)
	                if(this.next.length > 0) {
	                    const triggered = this.next[0]
	                    this.next.shift()
	                    this.shutterPanel().close('change_categorie')
	                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids, this.next)
	                } else { 
	                    this.$router.push({ name: 'HorseFicheNotes', params: {horse_id: this.horses_ids[0]} })
	                }
				}
				catch(e) {
					this.failureToast('toast.info_save_failed')
				}

				this.working = false
			},

			formatEmbedUrl(url) {
				// Si on a une url youtube, sans le format embed, on formatte l'url
				if(url.indexOf('youtube.com/watch') > -1) {
					const vid_id = url.split('=')[1]
					url = `https://www.youtube.com/embed/${vid_id}`
				}

				return url
			}
        },
		computed: {
			hasLinkedFile() {
				if(!this.form || this.form.files.length === 0) return true

				const hasUpatedFiles = this.form.files
					.filter(f => f.updated === true && f.content.size > 0 && f.content.name != 'blank')
					.length > 0

				const hasOnlyDefaultFile = this.form.files.length === 1
					&& this.form.files[0].content.size == 0 
					&& this.form.files[0].content.name == 'blank'

				const hasFiles = this.form.files
					.filter(f => f.content.size > 0 && f.content.name != 'blank')
					.length > 0

				return hasFiles
			},
			fileInputPlaceholder() {
				return this.form.filename || this.getTrad('fichier.select_file')+' :'
			},
			embedInputPlaceHolder() {
				return this.getTrad('fichier.copy_embed')+' :'
			},
			buttonText() {
				return this.$t('global.ajouter')
			},
			buttonFont() {
				if(this.working) return ['far', 'spinner']
				return ['far', 'plus']
			},
			isValidSizeFileMultiple() {
				if(!this.form) return true

                let isOk = true
                const limit = this.document_limit_size * 1000000 
                
				this.form.files.forEach(file => {
                    const file_size = file.content.size
                    
					if(file_size >= limit) {
                        isOk = false
                    }
                })

                return isOk
            },
            horsesName() {
                return this.horses_infos
                        .map(h => h.horse_nom)
                        .join(', ')
            },
			hasDedicatedClientArea() {
				return this.$store.state.userAccess.hasDedicatedClientArea
			}
		}
    }
</script>
